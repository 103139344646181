@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&family=Source+Code+Pro:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply h-full font-sans;
  }

  #root {
    @apply flex flex-col h-full;
  }

  :root {
    --color-ds-pink-default: 240, 31, 122;
    --color-ds-pink-secondary: 255, 74, 137;
    --color-ds-pink-tertiary: 209, 13, 98;

    --color-ds-blue-default: 0, 136, 233;
    --color-ds-blue-light: 82, 183, 255;
    --color-ds-blue-medium: 0, 149, 255;
    --color-ds-blue-darker: 0, 113, 194;
    --color-ds-blue-quinary: 1, 88, 150;
    --color-ds-blue-senary: 0, 45, 77;
    --color-ds-blue-selected: 230, 241, 255;
    --color-ds-blue-septenary: 34, 47, 61;

    --color-ds-gray-default: 14, 27, 41;
    --color-ds-gray-primary: 247, 248, 251;
    --color-ds-gray-secondary: 234, 235, 239;
    --color-ds-gray-tertiary: 216, 220, 226;
    --color-ds-gray-quaternary: 153, 159, 167;
    --color-ds-gray-quinary: 104, 115, 126;
    --color-ds-gray-senary: 57, 71, 84;
    --color-ds-gray-octonary: 14, 27, 41;

    --color-gray-300: 199, 203, 209;

    --color-orange-100: 255, 237, 213;
    --color-orange-500: 249, 115, 22;

    --color-ds-primary-green: 33, 181, 119;
    --color-ds-primary-red: 245, 32, 32;
    --color-ds-primary-purple: 172, 57, 207;
    --color-ds-primary-yellow: 244, 176, 27;
    --color-ds-primary-base: 14, 27, 41;

    --color-ds-coverage-partial: 255, 249, 216;

    --color-ds-error-quinary: 255, 155, 155;
    --color-ds-error-nonary: 89, 8, 8;

    --color-codecov-red: 206, 32, 25;
    --color-codecov-orange: 255, 194, 115;
    --color-codecov-footer: 7, 17, 27;

    --color-warning-100: 255, 235, 210;
    --color-warning-900: 71, 54, 16;

    --color-success-500: 115, 255, 158;
    --color-success-100: 222, 255, 232;

    --color-error-100: 255, 237, 240;

    --color-info-100: 223, 242, 255;
    --color-info-500: 163, 217, 255;
    --color-info-900: 1, 59, 101;

    --color-github: 51, 51, 51;
    --color-gitlab: 252, 109, 38;
    --color-bitbucket: 0, 82, 204;
    /* --color-okta: 0, 41, 122; */
    --color-okta: 25, 25, 25;
  }
}

#sentry-feedback {
  --submit-background: rgb(var(--color-ds-blue-darker));
  --submit-background-hover: rgb(var(--color-ds-blue-quinary));
}
